import MDRouter from "@/service/router";
import domain from '@/service/domain';
import { env } from "md-base-tools/env";
import { GetCollectList } from "@/api/index";
import { GoBack } from "@/utils/tools";
import CompEWM from '@@/luckycards/popupEWM.vue';
export default {
  components: {
    CompEWM
  },
  computed: {
    shareInfo() {
      if (this.list && this.list.length) {
        return this.list[0];
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      env,
      loading: false,
      finished: true,
      list: [],
      collect_id: '',
      collect_name: '',
      showEWM: false
    };
  },
  beforeMount() {
    const collect_id = this.$route.params.id ? this.$route.params.id.replace(".html", "") : "";
    this.collect_id = collect_id;
    // this.collect_name = this.$route.query.name ? this.$route.query.name : '摩点抽卡'

    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true;
      const {
        status,
        data
      } = await GetCollectList({
        collect_id: this.collect_id
      });
      if (status == 0) {
        this.list = data.list && data.list.length ? data.list : [];
        this.collect_name = data.collect_name || '摩点抽卡';
      } else {
        this.list = [];
      }
      this.loading = false;
    },
    goBack() {
      GoBack();
    },
    goInfo(id) {
      MDRouter.goLuckycardsInfo(id);
    },
    // 展示二维码弹层
    showJoin() {
      this.showEWM = true;
    },
    // 分享
    setShare() {
      if (!this.shareInfo) return;
      let url = `${domain.wap}/luckycards/fastsell/list?utm_source=Othershare&utm_content=${this.userId}`;
      let share_option = {
        url,
        img: this.shareInfo.list_main_image_url,
        msg_des: `向你分享好玩的在线抽卡项目 ｜ 摩点 ${url}`,
        qq_des: '向你分享好玩的在线抽卡项目 ｜ 摩点',
        qq_share_url: `${url}`,
        qq_title: '向你分享好玩的在线抽卡项目 ｜ 摩点',
        qr_img: "",
        qzone_share_url: `${url}`,
        share_channel: "",
        share_url: `${url}`,
        sharelink_url: `${url}`,
        weibo_des: `向你分享好玩的在线抽卡项目 ｜ 摩点 ${url}（分享自@摩点）`,
        weibo_share_url: `${url}`,
        weibo_title: '向你分享好玩的在线抽卡项目 ｜ 摩点',
        weixin_share_url: `${url}`,
        wxTimeline_share_url: `${url}`,
        wx_des: `向你分享好玩的在线抽卡项目 ｜ 摩点${url}`,
        wx_title: '向你分享好玩的在线抽卡项目 ｜ 摩点',
        mini_programs_title: `向你分享好玩的在线抽卡项目 ｜ 摩点${url}`,
        mina_share_img: this.shareInfo.list_main_image_url,
        mina_app_name: 'gh_7210ac3f1f08',
        mina_share_path: '/pages/index/index?nextUrl=pages/luckycards/collect_list/collect_list&id=' + this.collect_id
      };
      // eslint-disable-next-line no-undef
      userHeader.setShareInfo(share_option);
    },
    // 分享
    onShare() {
      if (!env.isWxApp()) {
        this.setShare();
        // eslint-disable-next-line no-undef
        userHeader.shareShow();
      }
    }
  }
};