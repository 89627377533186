export default {
  props: ['show'],
  computed: {
    showEWM: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      }
    }
  }
};